// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import * as atypes from "src/constants/actionTypes";
import { openConversationModal } from "src/actions/chatroom";
import {
  getSequenceNo,
  getChatroomTitle,
  getChatroomAddress,
  getTemplateId
} from "src/reducers";

import type { AppState, RoomId } from "src/types";

type Props = {
  seqNo: number,
  title: string,
  disableTitleHyperlink: boolean,
  address: string,
  id: RoomId,
  templatedId: ?number,
  style: Object,
  _openConversationModal: Function
};

const WithoutProcessTitle = ({
  seqNo,
  title,
  disableTitleHyperlink,
  address,
  id,
  templatedId,
  _openConversationModal,
  style = {}
}: Props) => {
  const handleConversation = () => {
    _openConversationModal(id);

    return false;
  };

  if (disableTitleHyperlink) {
    return (
      <div
        style={{
          ...style,
          backgroundColor: "transparent"
        }}
      >
        {seqNo && `#${seqNo}: `}
        {title || <i>Untitled</i>}
      </div>
    );
  }

  return (
    <Link
      onClick={handleConversation}
      to={{
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: address
        },
        meta: {
          query: {
            templatedId
          }
        }
      }}
      title={title}
      style={style}
    >
      {seqNo && `#${seqNo}: `}
      {title || <i>Untitled</i>}
    </Link>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  return {
    seqNo: getSequenceNo(app, props.id),
    title: getChatroomTitle(app, props.id),
    address: getChatroomAddress(app, props.id),
    templatedId: getTemplateId(app, props.id)
  };
};

export default connect(mapStateToProps, {
  _openConversationModal: openConversationModal
})(WithoutProcessTitle);
