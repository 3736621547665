// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";

import type { RoomId } from "src/types";
import * as atypes from "src/constants/actionTypes";
import { openConversationModal } from "src/actions/chatroom";

type Props = {
  seqNo: number,
  title: string,
  address: string,
  id: RoomId,
  templatedId: ?number,
  openConversationModal: Function
};

const Title = ({
  seqNo,
  title,
  address,
  id,
  templatedId,
  openConversationModal
}: Props) => {
  const handleConversation = () => {
    openConversationModal(id);
  };

  return (
    <Link
      onClick={handleConversation}
      to={{
        type: atypes.SET_CURRENT_CHATROOM_REQUEST,
        payload: {
          id: address
        },
        meta: {
          query: {
            templatedId
          }
        }
      }}
      title={title}
    >
      {seqNo && <>#{seqNo}: </>}
      {title ? <>{title}</> : <i>Untitled</i>}
    </Link>
  );
};

const mapDispatchToProps = {
  openConversationModal
};

export default connect(null, mapDispatchToProps)(Title);
